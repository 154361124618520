import React from 'react';
import withI18next from '../../i18n/TemplateI18n';
import { graphql } from 'gatsby';
import { TFunction } from 'i18next';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import Footer from '../../components/Footer';
import Props from '../../interface/template';
import ContextualCta from '../../components/ContextualCta';
import ListComponent from '../../components/ListComponent';
import FaqItem from '../../components/FaqItem';
import Layout from '../../components/atoms/layout';
import { LayoutFaqPage, PageLayout } from './style';
import DatoFaq from '../../services/datoFaq';
import useUserLocation from '../../hooks/useUserLocation';
import Banner from '../../components/Banner';

interface FaqProps extends Props {
  t: TFunction;
}

const IndexPage: React.SFC<any> = (props: FaqProps) => {
  const { t, pageContext } = props;
  const { locale, url, pageName } = pageContext;
  const questions = new DatoFaq().getFaqsByCategory(locale, 'global');
  const { userLocation } = useUserLocation();

  return (
    <PageLayout>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Header locale={locale} url={url} showBackground pageName={pageName}>
        <PageTitle
          title={t('faq:faq.mainHeadline.title')}
          subtitle={t('faq:faq.mainHeadline.subtitle')}
        />
      </Header>
      <Layout extend={LayoutFaqPage}>
        <ListComponent
          vocab="https://schema.org/"
          typeof="FAQPage"
          listItems={questions}
          ChildrenComponent={FaqItem}
        />
      </Layout>
      <ContextualCta className="contextual-cta" />
      <Footer page={pageName} />
    </PageLayout>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(faq)|(seo)|(footer)|(contextual-cta)|(eyebrow)|(breadcrumbs)|(banner)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(IndexPage);
