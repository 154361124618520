import styled, { css } from 'styled-components';
import config from '../../components/config';

const PageLayout = styled.section`
  .contextual-cta {
    margin-bottom: 4.8rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    .contextual-cta {
      margin-bottom: 3.2rem;
    }
  }
`;

const LayoutFaqPage = css`
  p:nth-child(2n) {
    font-weight: 700;
  }
`;

export { LayoutFaqPage, PageLayout };
