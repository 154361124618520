import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash/fp';

import { webLanguage } from './datoUtilities';
import { PropsDatoStaff, AllStaffs } from './interfaces';

export default class DatoFaq {
  private datoFaqs: AllFaqs;

  public constructor() {
    this.datoFaqs = this.getAllFaqs();
    this.faqs = this.datoFaqs.edges
      .map((node) => node.node)
      .filter((faq) => faq.question)
      .sort((faq1, faq2) => faq1.position - faq2.position);

    this.faqsByCategory = this.faqs.reduce(
      (byLocale, { locale, question, answer, categories }) => {
        const faqLocale = webLanguage(locale);
        _.pipe(
          JSON.parse,
          _.defaultTo([]),
          _.forEach((category) => {
            byLocale = _.update(
              `${faqLocale}.${category}`,
              _.pipe(
                _.defaultTo([]),
                _.concat(_, {
                  question,
                  answer,
                  category,
                })
              ),
              byLocale
            );
          })
        )(categories);
        return byLocale;
      },
      {}
    );
  }

  public getFaqsByCategory(locale: string, category: string): PropsDatoStaff[] {
    return _.getOr([], `${locale}.${category}`, this.faqsByCategory);
  }

  private getAllFaqs(): AllStaffs {
    const { allDatoCmsFaq } = useStaticQuery(
      graphql`
        query {
          allDatoCmsFaq {
            edges {
              node {
                answer
                categories
                question
                locale
                position
              }
            }
          }
        }
      `
    );
    return allDatoCmsFaq;
  }
}
