import styled from 'styled-components';

import config from '../config';
import { Text4 } from '../atoms/body';

const Span = styled.span`
  font-family: ${config.fonts.normal};
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 125%;
  margin-right: 1.7rem;
`;

export const Question = styled(Span)`
  color: ${config.palette.darkBlueTransparent('0.4')};
`;
export const Answer = styled(Span)`
  color: ${config.palette.skyBlue};
`;

export const AnswerText = styled(Text4)`
  font-size: 1.6rem;
  line-height: 150%;

  a {
    text-decoration: underline;
    color: ${config.palette.darkBlue};
    font-weight: bold;
  }
  /* When there is a leading space in the link text in DatoCMS, the link comes with
  an inline style with the font weight, so this is needed to overwrite it */
  a span[style] {
    font-weight: bold !important;
  }

  a:hover {
    text-decoration: none;
    background: #e6e8e8;
  }
`;

const Section = styled.section`
  display: flex;
  margin-bottom: 1.7rem;
`;

export const SectionQuestion = styled(Section)`
  div {
    color: #1e2334;
  }
`;

export const SectionAnswer = styled(Section)`
  margin-bottom: 4.7rem;
  opacity: 0.8;
  li {
    margin-left: 2rem;
    margin-top: 3.2rem;
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
`;
