import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text3 } from '../atoms/body';
import {
  Answer,
  AnswerText,
  Div,
  Question,
  SectionAnswer,
  SectionQuestion,
} from './Components';

type Props = {
  question: string;
  answer: [string];
};

const FaqItem = (props: Props): React.ReactElement => {
  const { question, answer } = props;
  const { t } = useTranslation(['faq']);

  return (
    <article
      itemScope
      itemType="https://schema.org/Question"
      property="mainEntity"
    >
      <SectionQuestion>
        <Question>{t('faq:faq.question')}</Question>
        <Text3 as="h3" itemProp="name">
          {question}
        </Text3>
      </SectionQuestion>

      <SectionAnswer
        itemProp="acceptedAnswer"
        itemScope
        itemType="https://schema.org/Answer"
      >
        <Answer>{t('faq:faq.answer')}</Answer>
        <Div itemProp="text">
          <AnswerText as="div" dangerouslySetInnerHTML={{ __html: answer }} />
        </Div>
      </SectionAnswer>
    </article>
  );
};

export default FaqItem;
